import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { SIJPHIRE_API_BASE } from '@/environment';

interface User {
  initials: string;
  username: string;
  groupCode: string;
  fullName: string;
  email: string;
}

interface UsersResponse {
  items: Array<User>;
}

/*
 * Usage:
 *
 * <div data-user-finder="input_name"></div>
 *
 * This will create the following elements:
 *
 * <div data-user-finder="input_name">
 *   <select id="5f4d37c1-5eb2-42d4-8408-331f9f25d320">
 *     <option value="">Selecteer gebruiker</option>
 *    ...
 *   </select>
 *   <label class="form-label select-label" for="5f4d37c1-5eb2-42d4-8408-331f9f25d320">
 *   <input type="hidden" name="input_name" value="...">
 *   <div data-validate-for="input_name" class="invalid-feedback">
 * </div>
 */
export const userFinderPlugin = (parent: HTMLElement): void => {
  const finders = parent.querySelectorAll<HTMLElement>('[data-user-finder]');

  const usersResponse = async () => {
    const url = SIJPHIRE_API_BASE + 'v1/order-entry/users';

    const response = await fetch(url);

    return await response.json();
  };

  finders.forEach((finder) => initFinder(finder, usersResponse()));
};

function initFinder(finder: HTMLElement, usersResponse: Promise<UsersResponse>): void {
  const elementId = uuidv4();

  // Empty option
  const optionElement = document.createElement('option');
  optionElement.innerHTML = 'Selecteer gebruiker';

  // Create <select> element.
  const selectElement = document.createElement('select');
  selectElement.setAttribute('id', elementId);
  selectElement.appendChild(optionElement);

  const labelElement = document.createElement('label');
  labelElement.innerHTML = 'Gebruiker';
  labelElement.setAttribute('class', 'form-label select-label');
  labelElement.setAttribute('for', elementId);

  // Create <input type="hidden" name="..."> element.
  const inputElement = document.createElement('input');
  inputElement.setAttribute('name', finder.dataset.userFinder || '');
  inputElement.setAttribute('type', 'hidden');

  // Create feedback element.
  const feedbackElement = document.createElement('div');
  feedbackElement.dataset.validateFor = finder.dataset.userFinder || '';
  feedbackElement.setAttribute('class', 'invalid-feedback');

  // Add the elements to the finder container.
  finder.appendChild(selectElement);
  finder.appendChild(labelElement);
  finder.appendChild(inputElement);
  finder.appendChild(feedbackElement);

  // Create the MDB-select element.
  new mdb.Select(selectElement, {
    noResultText: 'Geen gebruikers gevonden',
    searchPlaceholder: 'Zoek gebruiker',
    clearButton: true,
    filter: true,
  });

  // eslint-disable-next-line
    finder.addEventListener('valueChange.mdb.select', (e: any) => {
    const value = e.value || '';

    inputElement.setAttribute('value', value);
  });

  usersResponse.then((response) => {
    response.items.forEach((user) => {
      const inputElement = document.createElement('option');

      inputElement.setAttribute('value', user.username);
      inputElement.innerHTML = user.username + ' - ' + user.fullName;

      selectElement.appendChild(inputElement);
    });
  });
}
