import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { Contact } from '@/dto/Contact';
import { Customer } from '@/dto/Customer';
import { SIJPHIRE_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';

type ContactFinderName = 'contact' | 'delivery_contact';

interface Contacts {
  items: Contact[];
}

/*
 * Usage:
 *
 * <div data-contact-finder="input_name" class="my-css-class"></div>
 *
 * contactFinderPlugin()
 */
class ContactFinder {
  element: HTMLElement;
  name: ContactFinderName;
  selectElement: HTMLSelectElement;
  contacts: Array<Contact> = [];
  // eslint-disable-next-line
  mdbSelect: any;

  constructor(element: HTMLElement) {
    this.element = element;
    this.name = this.element.dataset.contactFinder as ContactFinderName;

    const elementId = uuidv4();

    // Create <select> element.
    this.selectElement = document.createElement('select');
    this.selectElement.setAttribute('id', elementId);
    this.selectElement.setAttribute('name', this.element.dataset.contactFinder || '');

    const firstOption = document.createElement('option');
    firstOption.value = '';
    firstOption.innerHTML = '';

    this.selectElement.appendChild(firstOption);

    const labelElement = document.createElement('label');
    labelElement.innerHTML = this.element.dataset.contactFinderLabel ?? 'Contactpersoon';
    labelElement.setAttribute('class', 'form-label select-label');
    labelElement.setAttribute('for', elementId);

    // Create <input type="hidden" name="..."> element.
    const hiddenInputElement = document.createElement('input');
    hiddenInputElement.setAttribute('name', this.element.dataset.contactFinder || '');
    hiddenInputElement.setAttribute('type', 'hidden');

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.contactFinder || '';
    feedbackElement.setAttribute('class', 'invalid-feedback');

    // Add the elements to the finder container.
    this.element.appendChild(this.selectElement);
    this.element.appendChild(labelElement);
    this.element.appendChild(hiddenInputElement);
    this.element.appendChild(feedbackElement);

    // Create the MDB-select element.
    this.mdbSelect = new mdb.Select(this.selectElement, {
      noResultText: 'Geen contactpersonen gevonden',
      searchPlaceholder: 'Zoek contactpersoon',
      clearButton: false,
      filter: false,
      disabled: true,
    });

    // eslint-disable-next-line
    this.element.addEventListener('valueChange.mdb.select', (e: any) => {
      const value = e.value || '';
      const contact = this.contacts[value] || null;

      this.setValue(contact);
    });

    const value: string | null = this.element.dataset.value || null;
    const customerNumber: string | null = this.element.dataset.customerNumber || null;

    if (customerNumber !== null) {
      this.reload(customerNumber, value);
    }

    EventBus.getInstance().register('customer.changed', (customer: Customer) => {
      this.reload(customer.number, null);

      this.mdbSelect.disabled = false;
    });
  }

  public setValue(contact: Contact): void {
    this.element.querySelectorAll<HTMLInputElement>('input[type=hidden]').forEach((element: HTMLInputElement) => {
      element.value = String(contact?.id);
    });

    this.selectElement.value = String(contact.id);

    EventBus.getInstance().dispatch<Contact | null>(`${this.name}.changed`, contact);
  }

  public reload(customerNumber: string, selected: string | null): void {
    // Remove all <option> elements.
    this.selectElement.querySelectorAll('option').forEach((element: HTMLOptionElement) => {
      this.selectElement.removeChild(element);
    });

    this.contacts = [];

    const firstOption = document.createElement('option');
    firstOption.value = '';
    firstOption.innerHTML = 'Selecteer contactpersoon';

    this.selectElement.appendChild(firstOption);

    // Fetch new data.
    fetch(SIJPHIRE_API_BASE + `v1/order-entry/contacts/${customerNumber}`)
      .then(async (response) => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data: Contacts = isJson ? await response.json() : null;

        if (!response.ok) {
          return Promise.reject(response.status);
        }

        let firstContact: Contact | null = null;

        data.items.forEach((contact: Contact) => {
          if (firstContact === null) {
            firstContact = contact;
          }

          const inputElement = document.createElement('option');
          inputElement.setAttribute('value', contact.id.toString());
          inputElement.innerHTML = contact.fullName + ' - ' + contact.phoneNumber;

          this.selectElement.appendChild(inputElement);
          this.contacts[contact.id] = contact;
        });

        if (firstContact !== null) {
          this.setValue(firstContact);
        }

        if (selected !== null && this.contacts[parseInt(selected)]) {
          this.setValue(this.contacts[parseInt(selected)]);
        }
      })
      .catch((error) => {
        console.error('Contacts lookup failed: ', error);
      });
  }
}

export const contactFinderPlugin = (parent: HTMLElement): void => {
  parent.querySelectorAll<HTMLElement>('[data-contact-finder]').forEach((finder) => {
    new ContactFinder(finder);
  });
};
