import '@popperjs/core';

import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { AjaxRequestErrorEvent } from 'octobercms';

import { EventBus } from '@/event-bus';
import { initDataVdlpTrackInput } from '@/plugins/data-vdlp-track-input';
import { executeDataRequestWhenVisible } from '@/plugins/execute-data-request-when-visbile';
import { initDatepickerExtensions } from '@/plugins/mdb/date-picker-extensions';
import { initializeMdbComponents } from '@/plugins/mdb/initialize-mdb-components';
import { initTabExtensions } from '@/plugins/mdb/tab-extensions';
import { initModalLoaders } from '@/plugins/modal-loader';
import { NewOrder, newOrderPlugin } from '@/plugins/new-order';
import { orderButtonsPlugin } from '@/plugins/order-buttons';
import { productSearchPlugin } from '@/plugins/search/product-search';
import { sidePanelLoaderPlugin } from '@/plugins/side-panel-loader';

EventBus.getInstance().register('initializePlugins', () => initializePlugins());

function init(): void {
  initializePlugins();
  newOrderPlugin();
  orderButtonsPlugin();
}

function initializePlugins(): void {
  initializeMdbComponents();
  initTabExtensions();
  initDatepickerExtensions();
  sidePanelLoaderPlugin();
  executeDataRequestWhenVisible();
  initModalLoaders();
  initDataVdlpTrackInput();
  productSearchPlugin();
}

init();

addEventListener('shown.mdb.modal', function () {
  executeDataRequestWhenVisible();
});

addEventListener('ajax:setup', function (event) {
  const options = event.detail.context.options;

  // Enable Flash Messages on all XHR responses (if not defined).
  if (options.flash === undefined) {
    options.flash = true;
  }

  // Enable Progress Bar by default. The progress bar will be shown when a request takes more than 500ms.
  if (options.progressBar === undefined) {
    options.progressBar = true;
  }
});

addEventListener('ajax:update-complete', function () {
  initializePlugins();
});

addEventListener('ajax:invalid-field', function (event) {
  if (event.detail === undefined) {
    return;
  }

  event.detail.element.classList.add('is-invalid');
});

addEventListener('ajax:promise', function (event) {
  if (event.target === null || !(event.target instanceof HTMLElement)) {
    return;
  }

  let form = null;

  if (event.target.dataset.requestForm !== undefined) {
    form = document.getElementById(event.target.dataset.requestForm.substring(1));
  } else {
    form = event.target.closest('form');
  }

  if (form === null) {
    return;
  }

  // Disable all submit buttons.
  form?.querySelectorAll<HTMLButtonElement>('button[type=submit]').forEach(function (element: HTMLButtonElement): void {
    element.disabled = true;
  });

  // Clear contents of validate-for elements.
  form?.querySelectorAll('[data-validate-for]').forEach(function (element: Element): void {
    element.innerHTML = '';
  });

  // Add .is-invalid css class to form elements.
  form?.querySelectorAll('.is-invalid').forEach(function (element: Element): void {
    element.classList.remove('is-invalid');
  });
});

const requestDoneHandler = function (event: Event) {
  if (event.target === null || !(event.target instanceof HTMLElement)) {
    return;
  }

  // Enable all submit buttons.
  event.target
    .closest('form')
    ?.querySelectorAll<HTMLButtonElement>('button[type=submit]')
    .forEach(function (element: HTMLButtonElement): void {
      element.disabled = false;
    });
};

addEventListener('ajax:request-success', requestDoneHandler);
addEventListener('ajax:request-error', requestDoneHandler);

addEventListener('ajax:request-error', function (event: AjaxRequestErrorEvent) {
  if (event.detail.responseCode !== 403) {
    return;
  }

  event.preventDefault();

  document.location.href = '/';
});

addEventListener('ajax:confirm-message', function (event) {
  const element = document.getElementById('sijphire-popconfirm');

  if (element === null) {
    return;
  }

  // Remove all previous added EventListeners by cloning the node.
  element.replaceWith(element.cloneNode(true));

  event.preventDefault();

  const confirm = new mdb.Popconfirm(element, {
    popconfirmMode: 'modal',
    message: event.detail?.message,
    cancelText: 'Nee',
    okText: 'Ja',
  });

  confirm.open();

  element.addEventListener('confirm.mdb.popconfirm', function (confirmEvent: Event) {
    const confirm = mdb.Popconfirm.getInstance(confirmEvent.target);

    if (confirm) {
      confirm.dispose();
    }

    event.detail.promise.resolve(event.detail.message);
  });

  element.addEventListener('cancel.mdb.popconfirm', function (confirmEvent: Event) {
    const confirm = mdb.Popconfirm.getInstance(confirmEvent.target);

    if (confirm) {
      confirm.dispose();
    }

    event.detail.promise.reject();
  });
});

// TODO: Move this logic somewhere else.
addEventListener('ajax:update-complete', function (event) {
  if (event.detail?.data?.['#newOrdersButtons'] !== undefined) {
    orderButtonsPlugin();
  }
});

// TODO: Move this logic somewhere else.
addEventListener('ajax:update-complete', function (event) {
  if (event.detail?.context.handler !== 'sijpHireOrderNewOrder::onCreate') {
    return;
  }

  const orderId = (event.detail?.data?.orderId || null) as string | null;

  if (orderId !== null) {
    new NewOrder(orderId);
  }
});
