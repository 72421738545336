import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { AjaxRequestSuccessEvent } from 'octobercms';

import { Contact } from '@/dto/Contact';
import { Customer } from '@/dto/Customer';
import { ShippingMethod } from '@/enum/ShippingMethod';
import { EventBus } from '@/event-bus';
import SelectEvent from '@/mdb/SelectEvent';
import { initializeOrderDeliveryAddress } from '@/order/delivery-address';
import { initializeOrderRentalPeriod } from '@/order/rental-period';
import { contactFinderPlugin } from '@/plugins/finders/contact-finder';
import { customerFinderPlugin } from '@/plugins/finders/customer-finder';
import { productFinderPlugin } from '@/plugins/finders/product-finder';
import { projectFinderPlugin } from '@/plugins/finders/project-finder';
import { referenceFinderPlugin } from '@/plugins/finders/reference-finder';
import { userFinderPlugin } from '@/plugins/finders/user-finder';

export const newOrderPlugin = (): void => {
  EventBus.getInstance().register('newOrder.modal.loaded', (orderId: string) => {
    const modalElement: HTMLElement | null = document.getElementById(orderId);
    const formElement: HTMLElement | null = document.getElementById('form-' + orderId);

    if (modalElement === null || formElement === null) {
      throw Error('Required elements could not be found.');
    }

    // Initialize form elements.
    contactFinderPlugin(modalElement);
    customerFinderPlugin(modalElement);
    referenceFinderPlugin(modalElement);
    projectFinderPlugin(modalElement);
    userFinderPlugin(modalElement);
    productFinderPlugin(modalElement, orderId);

    initializeOrderDeliveryAddress(modalElement);
    initializeOrderRentalPeriod(modalElement);

    const updateShippingMethod = (shippingMethod: ShippingMethod | undefined): void => {
      const deliveryAddressWrapper = document.getElementById(`${orderId}-develivery-address-wrapper`);
      const deliveryContactWrapper = document.getElementById(`${orderId}-delivery-contact-wrapper`);

      if (shippingMethod === ShippingMethod.PICKUP) {
        if (deliveryAddressWrapper) {
          deliveryAddressWrapper.style.display = 'none';
        }

        if (deliveryContactWrapper) {
          deliveryContactWrapper.style.display = 'none';
        }
      } else {
        if (deliveryAddressWrapper) {
          deliveryAddressWrapper.style.display = 'initial';
        }

        if (deliveryContactWrapper) {
          deliveryContactWrapper.style.display = 'initial';
        }
      }

      EventBus.getInstance().dispatch('shippingMethod.changed', shippingMethod);
    };

    const shippingMethodElement = document.getElementById(orderId + '-shipping_method') as HTMLSelectElement | null;
    const shippingMethodSelect = new mdb.Select(shippingMethodElement, {});

    updateShippingMethod(shippingMethodElement?.value ? (shippingMethodElement.value as ShippingMethod) : undefined);
    shippingMethodElement?.addEventListener('valueChange.mdb.select', (e: SelectEvent<ShippingMethod>) => {
      updateShippingMethod(e.value);
    });

    EventBus.getInstance().register('contact[id].changed', (contact: Contact) => {
      document.getElementById('contact-name-' + orderId)?.setAttribute('value', contact.fullName);
      document.getElementById('contact-phone-number-' + orderId)?.setAttribute('value', contact.phoneNumber);
      document.getElementById('contact-email-' + orderId)?.setAttribute('value', contact.email);
    });

    EventBus.getInstance().register('delivery_contact[id].changed', (contact: Contact) => {
      document.getElementById('delivery-contact-name-' + orderId)?.setAttribute('value', contact.fullName);
      document.getElementById('delivery-contact-phone-number-' + orderId)?.setAttribute('value', contact.phoneNumber);
    });

    EventBus.getInstance().register('customer.changed', (customer: Customer) => {
      window.oc.ajax('sijpHireOrderNewOrder::onLoadCustomerDetail', {
        data: {
          customerNumber: customer.number,
          wrapperId: 'customer-detail-' + orderId,
        },
        success: function (data, statusCode, xhr) {
          if (this.success === undefined) {
            return;
          }

          this.success(data, statusCode, xhr);
        },
      });
    });

    EventBus.getInstance().register('shippingMethod.changed', (shippingMethod: ShippingMethod) => {
      const others = [ShippingMethod.COLLECT, ShippingMethod.DELIVER_AND_COLLECT, ShippingMethod.DELIVER];

      if (!others.includes(shippingMethod)) {
        // TODO:
        shippingMethodSelect.disabled = true;
      }
    });

    addEventListener('ajax:request-success', function (event: AjaxRequestSuccessEvent): void {
      switch (event.detail.context.handler) {
        case 'sijpHireOrderNewOrder::onSave':
          mdb.Modal.getInstance(modalElement).hide();
          break;
        case 'sijpHireOrderNewOrder::onDelete':
        case 'sijpHireOrderNewOrder::onSaveAndCommit':
          mdb.Modal.getInstance(modalElement).dispose();
          modalElement.remove();
          break;
      }
    });

    formElement.addEventListener('keydown', (event): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  });
};

export class NewOrder {
  orderId: string;
  initialized: boolean;
  initiator: HTMLButtonElement | null = null;

  constructor(orderId: string, initiator?: HTMLButtonElement) {
    const modalElement: HTMLElement | null = document.getElementById(orderId);

    this.orderId = orderId;
    this.initialized = modalElement !== null;
    this.initiator = initiator || null;

    this.initModal();
  }

  private initModal(): void {
    const orderId = this.orderId;

    // The container which contains all modals.
    const modalsContainer: HTMLElement | null = document.getElementById('modals');

    if (modalsContainer === null) {
      throw Error('Required container elements cannot be found.');
    }

    const modalElement: HTMLElement | null = document.getElementById(orderId);

    if (this.initialized && modalElement !== null) {
      mdb.Modal.getInstance(modalElement).toggle();

      return;
    }

    // The modal wrapper element.
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'wrapper-' + this.orderId);

    modalsContainer.appendChild(wrapper);

    const initiator = this.initiator;

    if (initiator) {
      initiator.disabled = true;
    }

    window.oc.ajax('sijpHireOrderNewOrder::onLoadModal', {
      data: {
        orderId: this.orderId,
        wrapperId: 'wrapper-' + this.orderId,
      },
      success: function (data, statusCode, xhr) {
        if (this.success !== undefined) {
          this.success(data, statusCode, xhr);
        }

        const newOrderModal = new mdb.Modal(document.getElementById(orderId), {
          backdrop: true,
          keyboard: false,
          focus: true,
        });

        newOrderModal.show();

        EventBus.getInstance().dispatch<string>('newOrder.modal.loaded', orderId);
      },
      complete: function () {
        if (initiator) {
          initiator.disabled = false;
        }
      },
    });
  }
}
