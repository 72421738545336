import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

import { SIJPHIRE_API_BASE } from '@/environment';

dayjs.extend(CustomParseFormat);

interface OrderRentalPeriod {
  startDateTime: string;
  endDateTime: string;
  deliveryDateTime: string;
  endDateFixed: boolean;
  openingTimes: Array<string>;
  closingTimes: Array<string>;
  holidays: Array<string>;
}

class RentalPeriod {
  element: HTMLElement;
  holidays: Array<string> = [];
  depotElement: HTMLSelectElement | null = null;

  startDateElement: HTMLElement;
  startDateInputElement: HTMLInputElement;
  startTimeElement: HTMLSelectElement;
  endDateElement: HTMLElement;
  endDateInputElement: HTMLInputElement;
  endTimeElement: HTMLSelectElement;
  deliveryDateElement: HTMLElement;
  deliveryDateInputElement: HTMLInputElement;
  deliveryTimeElement: HTMLSelectElement;
  returnDateFixedInputElement: HTMLInputElement;

  previousStartDateTime: dayjs.Dayjs | null = null;
  previousEndDateTime: dayjs.Dayjs | null = null;
  previousDeliveryDateTime: dayjs.Dayjs | null = null;

  constructor(element: HTMLElement) {
    this.element = element;

    const startDateElement: HTMLElement | null = this.element.querySelector<HTMLElement>(
      '[data-rental-period-start-date]',
    );

    const startTimeElement: HTMLSelectElement | null = this.element.querySelector<HTMLSelectElement>(
      '[data-rental-period-start-time]',
    );

    const endDateElement: HTMLElement | null = this.element.querySelector<HTMLElement>('[data-rental-period-end-date]');

    const endTimeElement: HTMLSelectElement | null = this.element.querySelector<HTMLSelectElement>(
      '[data-rental-period-end-time]',
    );

    const deliveryDateElement: HTMLElement | null = this.element.querySelector<HTMLElement>(
      '[data-rental-period-delivery-date]',
    );

    const deliveryTimeElement: HTMLSelectElement | null = this.element.querySelector<HTMLSelectElement>(
      '[data-rental-period-delivery-time]',
    );

    const returnDateFixedElement: HTMLElement | null = this.element.querySelector<HTMLElement>(
      '[data-rental-period-return-date-fixed]',
    );

    if (
      startDateElement === null ||
      startTimeElement === null ||
      endDateElement === null ||
      endTimeElement === null ||
      deliveryDateElement === null ||
      deliveryTimeElement === null ||
      returnDateFixedElement === null
    ) {
      throw new Error('RentalPeriod: Missing required elements (data-attribute).');
    }

    const startDateInputElement: HTMLInputElement | null =
      startDateElement.querySelector<HTMLInputElement>('input[type=text]');

    const endDateInputElement: HTMLInputElement | null =
      endDateElement.querySelector<HTMLInputElement>('input[type=text]');

    const deliveryDateInputElement: HTMLInputElement | null =
      deliveryDateElement.querySelector<HTMLInputElement>('input[type=text]');

    const returnDateFixedInputElement: HTMLInputElement | null =
      returnDateFixedElement.querySelector<HTMLInputElement>('input[type=checkbox]');

    if (
      startDateInputElement === null ||
      endDateInputElement === null ||
      deliveryDateInputElement === null ||
      returnDateFixedInputElement === null
    ) {
      throw new Error('RentalPeriod: Missing required elements.');
    }

    this.startDateElement = startDateElement;
    this.startDateInputElement = startDateInputElement;
    this.startTimeElement = startTimeElement;
    this.endDateElement = endDateElement;
    this.endDateInputElement = endDateInputElement;
    this.endTimeElement = endTimeElement;
    this.deliveryDateElement = deliveryDateElement;
    this.deliveryDateInputElement = deliveryDateInputElement;
    this.deliveryTimeElement = deliveryTimeElement;
    this.returnDateFixedInputElement = returnDateFixedInputElement;

    if (
      'rentalPeriodHolidays' in this.element.dataset &&
      this.element.dataset.rentalPeriodHolidays &&
      this.element.dataset.rentalPeriodHolidays !== ''
    ) {
      this.holidays = this.element.dataset.rentalPeriodHolidays.split(',');
    }

    if ('rentalPeriodDepotFieldId' in this.element.dataset && this.element.dataset.rentalPeriodDepotFieldId) {
      const depotElement = document.getElementById(this.element.dataset.rentalPeriodDepotFieldId);

      if (depotElement === null || !(depotElement instanceof HTMLSelectElement)) {
        throw new Error('Depot select field not found with id: ' + this.element.dataset.rentalPeriodDepotFieldId);
      }

      this.depotElement = depotElement;
    }

    this.initElements();
  }

  private initElements(): void {
    const monthsFull = [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'December',
    ];
    const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    const weekdaysFull = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const weekdaysShort = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];
    const weekdaysNarrow = ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'];

    const startDateDatepicker = new mdb.Datepicker(this.startDateElement, {
      inline: true,
      disablePast: false,
      format: 'dd-mm-yyyy',
      title: 'Start huur',
      filter: (date: Date) => {
        return this.filterStartDate(date);
      },
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      weekdaysNarrow: weekdaysNarrow,
      cancelBtnLabel: 'Annuleer selectie',
      cancelBtnText: 'Annuleren',
      clearBtnLabel: 'Wis selectie',
      clearBtnText: 'Wissen',
      okBtnLabel: 'Selecteer',
      nextMonthLabel: 'Volgende maand',
      prevMonthLabel: 'Vorige maand',
      startDay: 1,
    });

    const endDateDatepicker = new mdb.Datepicker(this.endDateElement, {
      inline: true,
      disablePast: false,
      format: 'dd-mm-yyyy',
      title: 'Einde huur',
      filter: (date: Date) => {
        return this.filterEndDate(date);
      },
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      weekdaysNarrow: weekdaysNarrow,
      cancelBtnLabel: 'Annuleer selectie',
      cancelBtnText: 'Annuleren',
      clearBtnLabel: 'Wis selectie',
      clearBtnText: 'Wissen',
      okBtnLabel: 'Selecteer',
      nextMonthLabel: 'Volgende maand',
      prevMonthLabel: 'Vorige maand',
      startDay: 1,
    });

    const deliveryDatePicker = new mdb.Datepicker(this.deliveryDateElement, {
      inline: true,
      disablePast: false,
      format: 'dd-mm-yyyy',
      title: 'Afleverdatum',
      filter: (date: Date) => {
        return this.filterDeliveryDate(date);
      },
      monthsFull: monthsFull,
      monthsShort: monthsShort,
      weekdaysFull: weekdaysFull,
      weekdaysShort: weekdaysShort,
      weekdaysNarrow: weekdaysNarrow,
      cancelBtnLabel: 'Annuleer selectie',
      cancelBtnText: 'Annuleren',
      clearBtnLabel: 'Wis selectie',
      clearBtnText: 'Wissen',
      okBtnLabel: 'Selecteer',
      nextMonthLabel: 'Volgende maand',
      prevMonthLabel: 'Vorige maand',
      startDay: 1,
    });

    new mdb.Select(this.startTimeElement, {});
    new mdb.Select(this.endTimeElement, {});
    new mdb.Select(this.deliveryTimeElement, {});

    this.startDateInputElement.addEventListener('click', () => {
      startDateDatepicker.open();
    });

    this.endDateInputElement.addEventListener('click', () => {
      endDateDatepicker.open();
    });

    this.deliveryDateInputElement.addEventListener('click', () => {
      deliveryDatePicker.open();
    });

    this.startDateElement.addEventListener('dateChange.mdb.datepicker', () => {
      this.update();
    });

    this.endDateElement.addEventListener('dateChange.mdb.datepicker', () => {
      this.update();
    });

    this.deliveryDateElement.addEventListener('dateChange.mdb.datepicker', () => {
      this.update();
    });

    this.startTimeElement.addEventListener('valueChange.mdb.select', () => {
      this.update();
    });

    this.endTimeElement.addEventListener('valueChange.mdb.select', () => {
      this.update();
    });

    this.deliveryTimeElement.addEventListener('valueChange.mdb.select', () => {
      this.update();
    });

    this.returnDateFixedInputElement.addEventListener('change', () => {
      this.update();
    });

    this.depotElement?.addEventListener('change', () => {
      this.update();
    });
  }

  public update(): void {
    const startDateTime: dayjs.Dayjs = dayjs(
      this.startDateInputElement.value + ' ' + this.startTimeElement.value,
      'DD-MM-YYYY HH:mm',
    );
    const endDateTime: dayjs.Dayjs = dayjs(
      this.endDateInputElement.value + ' ' + this.endTimeElement.value,
      'DD-MM-YYYY HH:mm',
    );
    let deliveryDateTime: dayjs.Dayjs = dayjs(
      this.deliveryDateInputElement.value + ' ' + this.deliveryTimeElement.value,
      'DD-MM-YYYY HH:mm',
    );

    if (!startDateTime.isSame(this.previousStartDateTime, 'minute') && deliveryDateTime.isBefore(startDateTime)) {
      deliveryDateTime = startDateTime.clone();
    }

    const formData = new FormData();
    formData.append('startDateTime', startDateTime.format('YYYY-MM-DD HH:mm:ss'));
    formData.append('endDateTime', endDateTime.format('YYYY-MM-DD HH:mm:ss'));
    formData.append('deliveryDateTime', deliveryDateTime.format('YYYY-MM-DD HH:mm:ss'));
    formData.append('returnDateFixed', this.returnDateFixedInputElement.checked ? '1' : '0');
    formData.append('depotCode', this.depotElement?.value ?? '');

    const fetchOptions = {
      method: 'POST',
      body: formData,
    };

    fetch(SIJPHIRE_API_BASE + 'v1/order-entry/rental-period', fetchOptions)
      .then(async (response) => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data: OrderRentalPeriod = isJson ? await response.json() : null;

        if (!response.ok) {
          return Promise.reject(response.status);
        }

        this.updateElements(data);
      })
      .catch((error) => {
        console.error('Address lookup failed: ', error);
      });
  }

  private updateElements(rentalPeriod: OrderRentalPeriod): void {
    const startDate = dayjs(rentalPeriod.startDateTime, 'YYYY-MM-DD HH:mm:ss');
    const endDate = dayjs(rentalPeriod.endDateTime, 'YYYY-MM-DD HH:mm:ss');
    const deliveryDate = dayjs(rentalPeriod.deliveryDateTime, 'YYYY-MM-DD HH:mm:ss');

    this.previousStartDateTime = startDate;
    this.previousEndDateTime = endDate;
    this.previousDeliveryDateTime = deliveryDate;

    this.startDateInputElement.value = startDate.format('DD-MM-YYYY');
    this.endDateInputElement.value = endDate.format('DD-MM-YYYY');
    this.deliveryDateInputElement.value = deliveryDate.format('DD-MM-YYYY');

    // Remove all existing <option> elements.
    this.startTimeElement.querySelectorAll('option').forEach((element: HTMLOptionElement) => {
      this.startTimeElement.removeChild(element);
    });

    // Add new <option> elements.
    rentalPeriod.openingTimes.forEach((value: string) => {
      const option = document.createElement('option');
      option.value = value;
      option.innerHTML = value + ' uur';

      if (value === startDate.format('HH:mm')) {
        option.selected = true;
      }

      this.startTimeElement.appendChild(option);
    });

    // Remove all existing <option> elements.
    this.endTimeElement.querySelectorAll('option').forEach((element: HTMLOptionElement) => {
      this.endTimeElement.removeChild(element);
    });

    // Add new <option> elements.
    rentalPeriod.closingTimes.forEach((value: string) => {
      const option = document.createElement('option');
      option.value = value;
      option.innerHTML = value + ' uur';

      if (value === endDate.format('HH:mm')) {
        option.selected = true;
      }

      this.endTimeElement.appendChild(option);
    });

    // Remove all existing <option> elements.
    this.deliveryTimeElement.querySelectorAll('option').forEach((element: HTMLOptionElement) => {
      this.deliveryTimeElement.removeChild(element);
    });

    // Add new <option> elements.
    rentalPeriod.openingTimes.forEach((value: string) => {
      const option = document.createElement('option');
      option.value = value;
      option.innerHTML = value + ' uur';

      if (value === deliveryDate.format('HH:mm')) {
        option.selected = true;
      }

      this.deliveryTimeElement.appendChild(option);
    });
  }

  private filterStartDate(date: Date): boolean {
    const currentDate: dayjs.Dayjs = dayjs(date);

    if (currentDate.day() === 0) {
      return false;
    }

    if (currentDate.isBefore(dayjs().subtract(2, 'day'))) {
      return false;
    }

    return !this.holidays.includes(currentDate.format('YYYY-MM-DD'));
  }

  private filterEndDate(date: Date): boolean {
    const currentDate: dayjs.Dayjs = dayjs(date);

    if (currentDate.day() === 0) {
      return false;
    }

    if (this.holidays.includes(currentDate.format('YYYY-MM-DD'))) {
      return false;
    }

    const startDate: dayjs.Dayjs = dayjs(this.startDateInputElement?.value || dayjs(), 'DD-MM-YYYY');

    return currentDate.isAfter(startDate);
  }

  private filterDeliveryDate(date: Date): boolean {
    const currentDate: dayjs.Dayjs = dayjs(date);

    // Delivery not on Sunday and Saturday.
    if (currentDate.day() === 0 || currentDate.day() === 6) {
      return false;
    }

    // Delivery not on a Holiday.
    if (this.holidays.includes(currentDate.format('YYYY-MM-DD'))) {
      return false;
    }

    const startDate: dayjs.Dayjs = dayjs(this.startDateInputElement?.value || dayjs(), 'DD-MM-YYYY');
    const yesterday: dayjs.Dayjs = dayjs().subtract(1, 'day').set('hour', 7).set('minute', 0);

    return (currentDate.isBefore(startDate) || currentDate.isSame(startDate)) && currentDate.isAfter(yesterday);
  }
}

export const initializeOrderRentalPeriod = (parent: HTMLElement): void => {
  const element = parent.querySelector<HTMLElement>('[data-rental-period]');

  if (element === null) {
    return;
  }

  new RentalPeriod(element);
};
