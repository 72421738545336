import { SIJPHIRE_API_BASE } from '@/environment';

interface Address {
  postcode: string | null;
  houseNumber: string | null;
  houseNumberAddition: string | null;
  street: string | null;
  city: string | null;
}

class DeliveryAddress {
  element: HTMLElement;

  postcodeElement: HTMLInputElement;
  houseNumberElement: HTMLInputElement;
  houseNumberAdditionElement: HTMLInputElement;
  streetElement: HTMLInputElement;
  cityElement: HTMLInputElement;

  constructor(element: HTMLElement) {
    this.element = element;

    const postcodeElement: HTMLInputElement | null = element.querySelector<HTMLInputElement>(
      '[data-delivery-address-postcode]',
    );

    const houseNumberElement: HTMLInputElement | null = element.querySelector<HTMLInputElement>(
      '[data-delivery-address-house-number]',
    );

    const houseNumberAdditionElement: HTMLInputElement | null = element.querySelector<HTMLInputElement>(
      '[data-delivery-address-house-number-addition]',
    );

    const streetElement: HTMLInputElement | null = element.querySelector<HTMLInputElement>(
      '[data-delivery-address-street]',
    );

    const cityElement: HTMLInputElement | null =
      element.querySelector<HTMLInputElement>('[data-delivery-address-city]');

    if (
      postcodeElement === null ||
      houseNumberElement === null ||
      houseNumberAdditionElement === null ||
      streetElement === null ||
      cityElement === null
    ) {
      throw new Error('DeliveryAddress: Missing required element.');
    }

    this.postcodeElement = postcodeElement;
    this.houseNumberElement = houseNumberElement;
    this.houseNumberAdditionElement = houseNumberAdditionElement;
    this.streetElement = streetElement;
    this.cityElement = cityElement;

    this.addEventListeners();
  }

  private addEventListeners(): void {
    this.postcodeElement.addEventListener('change', () => {
      this.lookupAddress(this.getAddress());
    });

    this.houseNumberElement.addEventListener('change', () => {
      this.lookupAddress(this.getAddress());
    });

    this.houseNumberAdditionElement.addEventListener('change', () => {
      this.lookupAddress(this.getAddress());
    });
  }

  public getAddress(): Address {
    return {
      postcode: this.postcodeElement?.value,
      houseNumber: this.houseNumberElement?.value,
      houseNumberAddition: this.houseNumberAdditionElement?.value,
      street: this.streetElement?.value,
      city: this.cityElement?.value,
    };
  }

  private updateAddress(address: Address): void {
    this.postcodeElement.value = address.postcode || '';
    this.houseNumberElement.value = address.houseNumber || '';
    this.houseNumberAdditionElement.value = address.houseNumberAddition || '';
    this.streetElement.value = address.street || '';
    this.cityElement.value = address.city || '';
  }

  private lookupAddress(address: Address): void {
    if (address.postcode === null || address.houseNumber === null) {
      return;
    }

    if (address.postcode.length < 6 || address.houseNumber.length === 0) {
      return;
    }

    let url = SIJPHIRE_API_BASE + `v1/order-entry/addresses/${address.postcode}/${address.houseNumber}`;

    if (address.houseNumberAddition !== null && address.houseNumberAddition !== '') {
      url = url + `/${address.houseNumberAddition}`;
    }

    fetch(url)
      .then(async (response) => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data: Address = isJson ? await response.json() : null;

        if (!response.ok) {
          return Promise.reject(response.status);
        }

        this.updateAddress(data);
      })
      .catch((error) => {
        console.error('Address lookup failed: ', error);
      });
  }
}

export const initializeOrderDeliveryAddress = (parent: HTMLElement): void => {
  const element = parent.querySelector<HTMLElement>('[data-delivery-address]');

  if (element === null) {
    return;
  }

  new DeliveryAddress(element);
};
