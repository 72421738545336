import { showLoader } from '@/helpers/loader';

export const executeDataRequestWhenVisible = (): void => {
  document.querySelectorAll<HTMLElement>('[data-request-when-visible]').forEach((item) => init(item));
};

function init(item: HTMLElement): void {
  if (item.dataset.initialized) {
    return;
  }

  item.dataset.initialized = '1';

  const root = item.closest('.modal');

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const sourceElement = entry.target as HTMLElement;
        const targetElement = sourceElement.dataset.requestWhenVisible
          ? document.getElementById(sourceElement.dataset.requestWhenVisible)
          : sourceElement.parentElement;

        if (entry.intersectionRatio > 0) {
          sourceElement.click();

          if (targetElement) {
            showLoader(targetElement);
          }
        }
      });
    },
    { root: root ?? document.documentElement },
  );

  observer.observe(item);
}
