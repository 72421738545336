import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import logo from '@/assets/img/logo-blauw.svg';
import { Product } from '@/dto/Product';
import ProductSuggestion from '@/dto/ProductSuggestion';
import { SIJPERDA_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';
import AutocompleteEvent from '@/mdb/AutocompleteEvent';

/*
 * Usage:
 *
 * <div data-product-search="input_name" class="my-css-class"></div>
 *
 * productSearchPlugin()
 */
class ProductSearch {
  private readonly element: HTMLElement;
  private readonly customerNumber: string | null = null;

  constructor(element: HTMLElement) {
    this.element = element;

    if (this.element.dataset.productSearchInitialized) {
      return;
    }

    this.element.dataset.productSearchInitialized = '1';

    const elementId = uuidv4();

    const inputElement = document.createElement('input');
    inputElement.classList.add('form-control', 'form-control-search');
    inputElement.id = elementId;
    inputElement.placeholder = 'Zoeken';

    // @todo Jacob make label optional
    const labelElement = document.createElement('label');
    labelElement.innerHTML = 'Artikel';
    labelElement.classList.add('form-label');
    labelElement.htmlFor = elementId;

    // Create <input type="hidden" name="..."> element.
    const hiddenSearchTrackData = {
      vdlpTrackInput: this.element.dataset.vdlpTrackInput,
      vdlpTrackInputImmediately: this.element.dataset.vdlpTrackInputImmediately,
    };

    const hiddenSearchInput = this.makeHiddenInput('search', hiddenSearchTrackData);
    const hiddenSubGroupCodeInput = this.makeHiddenInput('sub_group_code');
    const hiddenProductIdInput = this.makeHiddenInput('product_id');

    let updateTimeout: number | undefined = undefined;

    const updateHiddenInputs = (search: string, subGroupCode: string, productId: string, emit = true): void => {
      clearTimeout(updateTimeout);

      updateTimeout = setTimeout(() => {
        hiddenSearchInput.value = search;
        hiddenSubGroupCodeInput.value = subGroupCode;
        hiddenProductIdInput.value = productId;

        if (emit) {
          hiddenSearchInput.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
        }
      }, 10);
    };

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.productSearch || '';
    feedbackElement.classList.add('invalid-feedback');

    // Add autocomplete classes
    this.element.classList.add('form-outline', 'autocomplete');

    // Add elements to wrapper
    this.element.appendChild(inputElement);
    //this.element.appendChild(labelElement); // @todo Jacob make label optional
    this.element.appendChild(hiddenSearchInput);
    this.element.appendChild(hiddenSubGroupCodeInput);
    this.element.appendChild(hiddenProductIdInput);
    this.element.appendChild(feedbackElement);

    // Init default values
    const defaultValues = this.element.dataset.productSearchData
      ? JSON.parse(this.element.dataset.productSearchData)
      : undefined;

    if (defaultValues !== undefined) {
      inputElement.value = defaultValues.search ?? '';
      updateHiddenInputs(
        defaultValues.search ?? '',
        defaultValues.subGroupCode ?? '',
        defaultValues.productId ?? '',
        false,
      );
    }

    // Create mdb autocomplete
    new mdb.Input(this.element).init();
    const productAutocomplete = new mdb.Autocomplete(this.element, {
      filter: (query: string) => {
        return this.asyncFilter(query);
      },
      displayValue: (product: Product | undefined) => product?.code + ' - ' + product?.term,
      itemContent: (product: Product) => {
        return `
              <div class="autocomplete-custom-item-content">
                <div class="d-flex flex-row">
                    <div class="flex-grow-0 me-2">
                        <img src="${product.image ?? logo}" alt="${product.term}" width="50" />
                    </div>
                    <div class="flex-grow-1">
                      <div class="autocomplete-custom-item-title">${product.term}</div>
                        <div class="autocomplete-custom-item-subtitle"><strong>${product.code}</strong></div>
                    </div>
                </div>
              </div>
            `;
      },
      noResults: 'Geen artikelen gevonden',
      threshold: 2,
      autoSelect: true,
    });

    // Reset input when clicked on the input
    inputElement.addEventListener('click', () => {
      inputElement.value = '';
      updateHiddenInputs('', '', '', false);
    });

    // Update hidden inputs on enter in search field
    inputElement.addEventListener('keydown', (event): void => {
      if (event.key === 'Enter') {
        updateHiddenInputs(inputElement.value, '', '');

        /// Close the dropdown in a timeout, so the itemSelect.mdb.autocomplete can be processed first.
        /// Otherwise we block the select event.
        setTimeout(() => productAutocomplete.close());
      }
    });

    // Update hidden inputs when autocomplete item has been selected.
    this.element.addEventListener('itemSelect.mdb.autocomplete', (e: AutocompleteEvent<ProductSuggestion>) => {
      if (e.value === undefined) {
        return;
      }

      updateHiddenInputs(e.value.term, e.value.code, e.value.id);
    });

    EventBus.getInstance().dispatch('initializePlugins');
  }

  private async asyncFilter(query: string): Promise<ProductSuggestion[]> {
    if (query.length < 3) {
      return [];
    }

    let url = SIJPERDA_API_BASE + `v1/products?query=${encodeURI(query)}`;

    if (this.customerNumber !== null) {
      url = SIJPERDA_API_BASE + `v1/products/${this.customerNumber}?query=${encodeURI(query)}`;
    }

    const response = await fetch(url);

    return (await response.json()) as ProductSuggestion[];
  }

  private makeHiddenInput(name: string, dataset?: DOMStringMap): HTMLInputElement {
    const hiddenInputElement = document.createElement('input');
    hiddenInputElement.name = `${this.element.dataset.productSearch}[${name}]`;
    hiddenInputElement.hidden = true;

    if (dataset !== undefined) {
      for (const [key, value] of Object.entries(dataset)) {
        hiddenInputElement.dataset[key] = value;
      }
    }

    return hiddenInputElement;
  }
}

export const productSearchPlugin = (): void => {
  document.querySelectorAll<HTMLElement>('[data-product-search]').forEach((finder) => {
    new ProductSearch(finder);
  });
};
