import mdbSidenav from '@vdlp/mdb-ui-kit-pro-advanced/js/modules/sidenav.min.js';

// import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced'; // TODO: Load/import SideNav module properly
import { showLoader } from '@/helpers/loader';

export const sidePanelLoaderPlugin = (): void => {
  const loaders = document.querySelectorAll<HTMLElement>('[data-side-panel-loader]');

  loaders.forEach((loader) => initLoader(loader));
};

function initLoader(loader: HTMLElement): void {
  if (loader.dataset.initialized) {
    return;
  }

  loader.dataset.initialized = '1';

  let data = {};

  try {
    data = loader.dataset.sidePanelLoaderData ? JSON.parse(loader.dataset.sidePanelLoaderData) : {};
  } catch (e) {
    data = {};
  }

  const target = loader.dataset.sidePanelLoaderTarget;
  const handler = loader.dataset.sidePanelLoaderHandler;
  const drawerMain = loader.dataset.sidePanelLoaderDrawerMain;

  if (target === undefined) {
    console.error('No target defined for the side panel loader.');
    return;
  }

  if (handler === undefined) {
    console.error('No ajax handler defined for the side panel loader.');
    return;
  }

  if (drawerMain === undefined) {
    console.error('No drawer main defined for the side panel loader.');
    return;
  }

  const targetElement = document.querySelector(target);

  if (targetElement === null) {
    console.error('Target div for side panel loader not found.');
    return;
  }

  const sidenavInstance = mdbSidenav.getOrCreateInstance(targetElement);

  loader.addEventListener('click', () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    sidenavInstance.show();

    showLoader(targetElement);

    window.oc.ajax<{ result: string } | string>(handler, {
      data,
      bulk: true, // sends json
      success: function (data, status, xhr) {
        if (status === 200) {
          targetElement.innerHTML = typeof data === 'string' ? data : data.result;
        }

        if (this.success === undefined) {
          return;
        }

        this.success(data, status, xhr);
      },
    });
  });
}
