import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import dayjs, { Dayjs } from 'dayjs';

import DateChangeEvent from '@/mdb/DateChangeEvent';

export const initDatepickerExtensions = (): void => {
  initDatepickerNextAndPreviousButtons();
};

const handleNextPrevious = (key: string, event: Event, callback: (date: Dayjs) => Dayjs): void => {
  const target = event.target as HTMLElement;
  const datepickerId = target.dataset[key];

  if (!datepickerId) {
    console.error('Datepicker id for next and/or previous button is undefined.');
    return;
  }

  const datepicker = mdb.Datepicker.getOrCreateInstance(document.getElementById(datepickerId));

  const input: HTMLInputElement = datepicker._input;
  const format = (datepicker._options.format as string).toUpperCase();

  const date = dayjs(input.value, format);
  const nextDate = callback(date);

  // Update input value
  input.value = nextDate.format(format);

  // Dispatch mdb change event
  const dateChangeEvent: DateChangeEvent = new Event('dateChange.mdb.datepicker', {
    bubbles: true,
    cancelable: true,
  });
  dateChangeEvent.date = date.toDate();
  input.dispatchEvent(dateChangeEvent);
};

const datepickerPreviousButtonCallback = (event: Event): void => {
  handleNextPrevious('vdlpDatepickerPrevious', event, (date) => date.subtract(1, 'day'));
};

const datepickerNextButtonCallback = (event: Event): void => {
  handleNextPrevious('vdlpDatepickerNext', event, (date) => date.add(1, 'day'));
};

const initDatepickerNextAndPreviousButtons = (): void => {
  const prevButtons = document.querySelectorAll<HTMLElement>('[data-vdlp-datepicker-previous]');
  const nextButtons = document.querySelectorAll<HTMLElement>('[data-vdlp-datepicker-next]');

  for (const prevButton of prevButtons) {
    // cleanup old listener
    prevButton.removeEventListener('click', datepickerPreviousButtonCallback);

    // set new listener
    prevButton.addEventListener('click', datepickerPreviousButtonCallback);
  }

  for (const nextButton of nextButtons) {
    // cleanup old listener
    nextButton.removeEventListener('click', datepickerNextButtonCallback);

    // set new listener
    nextButton.addEventListener('click', datepickerNextButtonCallback);
  }
};
