import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { Customer } from '@/dto/Customer';
import { SIJPHIRE_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';

interface Customers {
  data: {
    items: Customer[];
  };
}

/*
 * Usage:
 *
 * <div data-customer-finder="input_name" class="my-css-class"></div>
 *
 * customerFinderPlugin()
 */
class CustomerFinder {
  element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;

    const elementId = uuidv4();

    const inputElement = document.createElement('input');
    inputElement.setAttribute('class', 'form-control');
    inputElement.setAttribute('id', elementId);
    inputElement.setAttribute('placeholder', 'Zoek klant');
    inputElement.setAttribute('name', this.element.dataset.customerFinder || '');

    const labelElement = document.createElement('label');
    labelElement.innerHTML = this.element.dataset.customerFinderLabel || 'Klant';
    labelElement.setAttribute('class', 'form-label');
    labelElement.setAttribute('for', elementId);

    // Create <input type="hidden" name="..."> element.
    const hiddenInputElement = document.createElement('input');
    hiddenInputElement.setAttribute('name', this.element.dataset.customerFinder || '');
    hiddenInputElement.setAttribute('type', 'hidden');

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.customerFinder || '';
    feedbackElement.setAttribute('class', 'invalid-feedback');

    this.element.setAttribute('class', ('form-outline autocomplete ' + this.element.getAttribute('class')).trim());
    this.element.appendChild(inputElement);
    this.element.appendChild(labelElement);
    this.element.appendChild(hiddenInputElement);
    this.element.appendChild(feedbackElement);

    new mdb.Input(this.element).init();

    new mdb.Autocomplete(this.element, {
      filter: (query: string) => {
        return this.asyncFilter(query);
      },
      displayValue: (customer: Customer) => customer.name,
      itemContent: (customer: Customer) => {
        return `
          <div class="autocomplete-custom-item-content">
            <div class="autocomplete-custom-item-title">${customer.name}</div>
            <div class="autocomplete-custom-item-subtitle">
                 ${customer.number} - ${customer.addressInfo.street}, ${customer.addressInfo.city}
            </div>
          </div>
        `;
      },
      noResults: 'Geen klanten gevonden',
      threshold: 2,
      autoSelect: true,
    });

    const value: string | null = this.element.dataset.value || null;
    const displayValue: string | null = this.element.dataset.displayValue || null;

    if (value !== null) {
      hiddenInputElement.value = value;
    }

    if (displayValue !== null) {
      inputElement.value = displayValue;
    }

    // eslint-disable-next-line
        this.element.addEventListener('itemSelect.mdb.autocomplete', (event: any) => {
      const customer = event.value;

      hiddenInputElement.setAttribute('value', customer.number);

      EventBus.getInstance().dispatch<string>('customer.changed', customer);
    });
  }

  async asyncFilter(query: string) {
    if (query.length < 3) {
      return [];
    }

    const response = await fetch(SIJPHIRE_API_BASE + `v1/order-entry/customers?page=1&query=${encodeURI(query)}`);
    const customers: Customers = await response.json();

    return customers.data.items;
  }
}

export const customerFinderPlugin = (parent: HTMLElement): void => {
  parent.querySelectorAll<HTMLElement>('[data-customer-finder]').forEach((finder) => {
    new CustomerFinder(finder);
  });
};
