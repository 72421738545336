import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';

import { showLoader } from '@/helpers/loader';

export const initModalLoaders = (): void => {
  const elements = document.querySelectorAll('[data-vdlp-modal-loader]');

  for (const element of elements) {
    // cleanup old listener
    element.removeEventListener('click', initModalLoaderListener);

    // set new listener
    element.addEventListener('click', initModalLoaderListener);
  }
};

const initModalLoaderListener = (event: Event): void => {
  const target = event.target as HTMLElement;

  const modalId = target.dataset.vdlpModalLoaderModalId;

  if (modalId === undefined || modalId === '') {
    console.error('No modal id defined for the modal loader.');
    return;
  }

  const modalElement = document.getElementById(modalId);

  if (modalElement === null) {
    console.error(`Modal with id: ${modalId} not found.`);
    return;
  }

  const modalContentId = target.dataset.vdlpModalLoaderModalContentId;

  if (modalContentId === undefined || modalContentId === '') {
    console.error('No modal content id defined for the modal loader.');
    return;
  }

  const modalContentElement = document.getElementById(modalContentId);

  if (modalContentElement === null) {
    console.error(`Modal content with id: ${modalContentId} not found.`);
    return;
  }

  const handler = target.dataset.vdlpModalLoaderHandler;

  if (handler === undefined || handler === '') {
    console.error('No ajax handler defined for the modal loader.');
    return;
  }

  showLoader(modalContentElement);

  const data = target.dataset.vdlpModalLoaderData ? JSON.parse(target.dataset.vdlpModalLoaderData) : {};

  const modal = mdb.Modal.getInstance(modalElement) ?? new mdb.Modal(modalElement);

  modal.show();

  window.oc.ajax<{ result: string } | string>(handler, {
    data,
    bulk: true, // sends json
    success: function (data, status, xhr) {
      if (status === 200) {
        modalContentElement.innerHTML = typeof data === 'string' ? data : data.result;
      }

      if (this.success === undefined) {
        return;
      }

      this.success(data, status, xhr);
    },
  });
};
