import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { Customer } from '@/dto/Customer';
import { SIJPHIRE_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';

interface Projects {
  items: Project[];
}

interface Project {
  code: string;
  description: string;
}

/*
 * Usage:
 *
 * <div data-project-finder="input_name" class="my-css-class"></div>
 *
 * projectFinderPlugin()
 */
class ProjectFinder {
  element: HTMLElement;
  customerNumber: string | null = null;

  constructor(element: HTMLElement) {
    this.element = element;

    const elementId = uuidv4();

    const inputElement = document.createElement('input');
    inputElement.setAttribute('class', 'form-control');
    inputElement.setAttribute('id', elementId);
    inputElement.setAttribute('placeholder', 'Zoek project');
    inputElement.disabled = true;

    const labelElement = document.createElement('label');
    labelElement.innerHTML = 'Project';
    labelElement.setAttribute('class', 'form-label');
    labelElement.setAttribute('for', elementId);

    // Create <input type="hidden" name="..."> element.
    const hiddenInputElement = document.createElement('input');
    hiddenInputElement.setAttribute('name', this.element.dataset.projectFinder || '');
    hiddenInputElement.setAttribute('type', 'hidden');

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.projectFinder || '';
    feedbackElement.setAttribute('class', 'invalid-feedback');

    this.element.setAttribute('class', ('form-outline autocomplete ' + this.element.getAttribute('class')).trim());
    this.element.appendChild(inputElement);
    this.element.appendChild(labelElement);
    this.element.appendChild(hiddenInputElement);
    this.element.appendChild(feedbackElement);

    new mdb.Input(this.element).init();

    new mdb.Autocomplete(this.element, {
      filter: (query: string) => {
        return this.asyncFilter(query);
      },
      displayValue: (project: Project) => project.code + ' - ' + project.description,
      itemContent: (project: Project) => {
        return `
                  <div class="autocomplete-custom-item-content">
                    <div class="autocomplete-custom-item-title">${project.description}</div>
                    <div class="autocomplete-custom-item-subtitle"><strong>${project.code}</strong></div>
                  </div>
                `;
      },
      noResults: 'Geen projecten gevonden',
      debounce: 200,
      threshold: 2,
    });

    // eslint-disable-next-line
        this.element.addEventListener('itemSelect.mdb.autocomplete', (event: any) => {
      const item = event.value;

      hiddenInputElement.setAttribute('value', item.code);
    });

    const value: string | null = this.element.dataset.value || null;
    const displayValue: string | null = this.element.dataset.displayValue || null;
    const customerNumber: string | null = this.element.dataset.customerNumber || null;

    if (customerNumber !== null) {
      this.customerNumber = customerNumber;
      inputElement.disabled = false;

      if (value !== null) {
        hiddenInputElement.value = value;
      }

      if (displayValue !== null) {
        inputElement.value = displayValue;
      }
    }
    EventBus.getInstance().register('customer.changed', (customer: Customer) => {
      this.customerNumber = customer.number;
      inputElement.disabled = !customer.collectiveInvoicing;
    });
  }

  async asyncFilter(query: string) {
    if (this.customerNumber === null) {
      return [];
    }

    const response = await fetch(
      SIJPHIRE_API_BASE + `v1/order-entry/projects/${this.customerNumber}?page=1&query=${encodeURI(query)}`,
    );

    const projects: Projects = await response.json();

    return projects.items;
  }
}

export const projectFinderPlugin = (parent: HTMLElement): void => {
  parent.querySelectorAll<HTMLElement>('[data-project-finder]').forEach((finder) => {
    new ProjectFinder(finder);
  });
};
