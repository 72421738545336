import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { Customer } from '@/dto/Customer';
import { SIJPHIRE_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';

interface References {
  items: Reference[];
}

interface Reference {
  reference: string;
}

interface ReferenceMask {
  mask: string | null;
}

/*
 * Usage:
 *
 * <div data-reference-finder="input_name" class="my-css-class"></div>
 *
 * referenceFinderPlugin()
 */
class ReferenceFinder {
  element: HTMLElement;
  inputElement: HTMLInputElement;
  customerNumber: string | null = null;
  inpshireMask: string | null;

  constructor(element: HTMLElement) {
    this.element = element;
    this.inpshireMask = null;

    const elementId = uuidv4();

    this.inputElement = document.createElement('input');
    this.inputElement.setAttribute('name', this.element.dataset.referenceFinder || '');
    this.inputElement.setAttribute('class', 'form-control');
    this.inputElement.setAttribute('id', elementId);

    const labelElement = document.createElement('label');
    labelElement.innerHTML = this.element.dataset.referenceFinderLabel || 'Referentie';
    labelElement.setAttribute('class', 'form-label');
    labelElement.setAttribute('for', elementId);

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.referenceFinder || '';
    feedbackElement.setAttribute('class', 'invalid-feedback');

    const inputWrapper = document.createElement('div');

    inputWrapper.setAttribute('class', 'form-outline autocomplete');
    inputWrapper.appendChild(this.inputElement);
    inputWrapper.appendChild(labelElement);
    inputWrapper.appendChild(feedbackElement);

    this.element.appendChild(inputWrapper);

    new mdb.Input(inputWrapper).init();

    new mdb.Autocomplete(inputWrapper, {
      displayValue: (reference: Reference) => reference.reference,
      filter: (query: string) => {
        return this.asyncFilter(query);
      },
      noResults: 'Geen referentie gevonden',
      threshold: 2,
      autoSelect: false,
    });

    const value: string | null = this.element.dataset.value || null;

    if (value !== null) {
      this.inputElement.value = value;
    }

    EventBus.getInstance().register('customer.changed', (customer: Customer) => {
      this.customerNumber = customer.number;
      console.log(this.customerNumber);
      this.updateReferenceMask();
    });
  }

  async asyncFilter(query: string) {
    if (this.customerNumber === null || query.length < 3) {
      return [];
    }

    const response = await fetch(
      SIJPHIRE_API_BASE + `v1/order-entry/references/${this.customerNumber}?query=${encodeURI(query)}`,
    );

    const references: References = await response.json();

    return references.items;
  }

  async updateReferenceMask() {
    const response = await fetch(SIJPHIRE_API_BASE + `v1/order-entry/references/${this.customerNumber}/mask`);

    const data: ReferenceMask = await response.json();

    this.inpshireMask = data.mask;

    let helperElement = this.element.querySelector('.form-text');

    if (!this.inpshireMask) {
      if (helperElement !== null) {
        this.element.removeChild(helperElement);
      }

      this.inputElement.removeEventListener('keydown', this.handleInpshireMaskInInput);

      return;
    }

    if (helperElement === null) {
      helperElement = document.createElement('div');
      helperElement.classList.add('form-text');
    }

    helperElement.innerHTML =
      `De referentie moet voldoen aan het volgende patroon: ${this.inpshireMask}<br/>` +
      '<ul><li># gebruik een cijfer</li><li>? gebruik een letter</li><li>\\ neem het volgende karakter in het patroon letterlijk over.</li></ul>';

    this.inputElement.addEventListener('keydown', this.handleInpshireMaskInInput.bind(this));

    this.element.appendChild(helperElement);
  }

  handleInpshireMaskInInput(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      return;
    }

    if (!this.inpshireMask) {
      return;
    }

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const operatorExact = '\\';

    const combineOperators = [operatorExact];

    const alphabetRegex = /[a-zA-Z]/;

    let maskLength = 0;
    let maskOperatorToCombine = '';

    debugger;

    const newValue = event.target.value + event.key;

    for (let i = 0; i < this.inpshireMask.length; i++) {
      if (maskLength >= newValue.length) {
        break; // We can't check more characters than the input value has.
      }

      const maskOperator = maskOperatorToCombine + this.inpshireMask[i];
      const valueChar = newValue[maskLength];

      if (combineOperators.includes(maskOperator)) {
        maskOperatorToCombine = maskOperator;

        continue;
      }

      maskOperatorToCombine = '';
      maskLength++;

      if (maskOperator.startsWith(operatorExact)) {
        if (this.handleExactOperator(event, maskOperator.substring(operatorExact.length), valueChar)) {
          continue;
        }

        event.preventDefault();

        return;
      }

      if (maskOperator === '#' && !isNaN(parseInt(valueChar))) {
        continue;
      }

      if (maskOperator === '?' && alphabetRegex.test(valueChar)) {
        continue;
      }

      if (this.handleExactOperator(event, maskOperator, valueChar)) {
        continue;
      }

      event.preventDefault();

      return;
    }

    if (maskLength < newValue.length) {
      event.preventDefault();
    }
  }

  handleExactOperator = (event: KeyboardEvent, maskOperator: string, valueChar: string | undefined) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return false;
    }

    if (maskOperator === valueChar) {
      return true;
    }

    if (maskOperator === valueChar?.toUpperCase()) {
      // Upgrade char to upper case in input value
      event.target.value = event.target.value + valueChar.toUpperCase();

      // Prevent lower case char in input
      event.preventDefault();

      return true;
    }

    return false;
  };
}

export const referenceFinderPlugin = (parent: HTMLElement): void => {
  parent.querySelectorAll<HTMLElement>('[data-reference-finder]').forEach((finder) => {
    new ReferenceFinder(finder);
  });
};
