import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';

export const initializeMdbComponents = () => {
  initializeFormOutline();
  initializeSelects();
};

function initializeFormOutline(): void {
  document.querySelectorAll('.form-outline').forEach((element: Element) => {
    new mdb.Input(element).init();
  });
}

function initializeSelects(): void {
  document.querySelectorAll('select').forEach((element: Element) => {
    mdb.Select.getOrCreateInstance(element);
  });
}
