import { NewOrder } from '@/plugins/new-order';

export const orderButtonsPlugin = (): void => {
  const buttons = document.querySelectorAll<HTMLButtonElement>('button[data-new-order]');

  buttons.forEach((button: HTMLButtonElement) => {
    if (button.dataset.initialized === 'true') {
      return;
    }

    button.dataset.initialized = 'true';

    button.addEventListener('click', (): void => {
      const orderId = button.dataset.newOrder || null;

      if (orderId === null) {
        return;
      }

      new NewOrder(orderId, button);
    });
  });
};

addEventListener('show.mdb.modal', function (event: Event) {
  const target = event.target as HTMLElement;

  if (target.dataset.modalType !== 'order') {
    return;
  }

  updateNewOrderButtonStates(target.id);
});

addEventListener('hide.mdb.modal', function (event: Event) {
  const target = event.target as HTMLElement;

  if (target.dataset.modalType !== 'order') {
    return;
  }

  updateNewOrderButtonStates(null);
});

function updateNewOrderButtonStates(orderId?: string | null): void {
  const buttons = document.querySelectorAll<HTMLButtonElement>('button[data-new-order]');

  buttons.forEach((button: HTMLButtonElement) => {
    if (orderId === null) {
      // Clear states
      button.classList.remove('btn-badge-active');
      button.disabled = false;
    } else if (button.dataset.newOrder === orderId) {
      // Set state active for matching Order ID.
      button.classList.add('btn-badge-active');
      button.disabled = false;
    } else {
      // Set state inactive and disable button.
      button.classList.remove('btn-badge-active');
      button.disabled = true;
    }
  });
}
