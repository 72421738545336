export const initDataVdlpTrackInput = (): void => {
  handleTrackInputs(document.querySelectorAll<HTMLInputElement>('input[data-vdlp-track-input]'));
  handleTrackSelects(document.querySelectorAll<HTMLSelectElement>('select[data-vdlp-track-input]'));
};

const handleTrackInputs = (inputs: NodeListOf<HTMLInputElement>): void => {
  for (const input of inputs) {
    if (input.dataset.vdlpTrackInputDatepicker !== undefined) {
      const datepickerElement = document.getElementById(input.dataset.vdlpTrackInputDatepicker);

      if (datepickerElement === null) {
        console.error(`Datepicker with ${input.dataset.vdlpTrackInputDatepicker} not found.`);
        continue;
      }

      datepickerElement.removeEventListener('dateChange.mdb.datepicker', handleTrackDatepickerChange);
      datepickerElement.addEventListener('dateChange.mdb.datepicker', handleTrackDatepickerChange);

      continue;
    }

    if (input.dataset.vdlpTrackInputOnEnter !== undefined) {
      input.removeEventListener('keydown', handleTrackEnter);
      input.addEventListener('keydown', handleTrackEnter);

      continue;
    }

    input.removeEventListener('input', handleTrackInput);
    input.addEventListener('input', handleTrackInput);
  }
};

const handleTrackSelects = (selects: NodeListOf<HTMLSelectElement>): void => {
  for (const select of selects) {
    select.removeEventListener('change', handleTrackSelect);
    select.addEventListener('change', handleTrackSelect);
  }
};

const handleTrackDatepickerChange = (event: Event): void => {
  handleConfigurableTrackInput(event.target as HTMLDivElement);
};

const handleTrackInput = (event: Event): void => {
  handleConfigurableTrackInput(event.target as HTMLInputElement);
};

const handleTrackSelect = (event: Event): void => {
  handleConfigurableTrackInput(event.target as HTMLSelectElement);
};

const handleTrackEnter = (event: KeyboardEvent): void => {
  if (event.key === 'Enter') {
    handleConfigurableTrackInput(event.target as HTMLSelectElement);
  }
};

let trackInputTimeout: number | undefined = undefined;

const handleConfigurableTrackInput = (element: HTMLElement): void => {
  if (element.dataset.vdlpTrackInputImmediately !== undefined) {
    postClosestForm(element);

    return;
  }

  clearTimeout(trackInputTimeout);
  trackInputTimeout = setTimeout(() => postClosestForm(element), 300);
};

const postClosestForm = (element: HTMLElement): void => {
  const form = element.closest('form');

  if (!form) {
    console.error('No closest form found for the track input element.');
    return;
  }

  form.dispatchEvent(
    new Event('submit', {
      bubbles: true,
      cancelable: true,
    }),
  );
};
