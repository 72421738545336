export const initTabExtensions = (): void => {
  initSelectTabListExtension();
};

const selectTabListChangeListener = (event: Event): void => {
  const target = event.target as HTMLSelectElement;

  for (const option of target.options) {
    const tabTargetId = option.dataset.vdlpTabTarget;

    if (tabTargetId === undefined) {
      throw new Error('data-vdlp-tab-target on select option is empty');
    }

    if (!option.selected) {
      continue;
    }

    const tabTarget = document.getElementById(tabTargetId) as HTMLAnchorElement | undefined;

    if (tabTarget === undefined) {
      throw new Error('Could not find data-vdlp-tab-target with id: ' + tabTarget);
    }

    const tabContainer = tabTarget.parentElement;

    for (const tab of tabContainer?.children ?? []) {
      tab.classList.remove('show');

      if (tab.classList.contains('active')) {
        setTimeout(() => {
          tab.classList.remove('active');
          tabTarget.classList.add('show', 'active');
        }, 150);
      }
    }
  }
};

const initSelectTabListExtension = (): void => {
  const selects = document.querySelectorAll<HTMLSelectElement>('[data-vdlp-select-tablist]');

  for (const select of selects) {
    // cleanup old listener
    select.removeEventListener('change', selectTabListChangeListener);

    // set new listener
    select.addEventListener('change', selectTabListChangeListener);
  }
};
