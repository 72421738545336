import * as mdb from '@vdlp/mdb-ui-kit-pro-advanced';
import { v4 as uuidv4 } from 'uuid';

import { Customer } from '@/dto/Customer';
import { Product } from '@/dto/Product';
import { SIJPERDA_API_BASE } from '@/environment';
import { EventBus } from '@/event-bus';

/*
 * Usage:
 *
 * <div data-product-finder="input_name" class="my-css-class"></div>
 *
 * productFinderPlugin()
 */
class ProductFinder {
  element: HTMLElement;
  orderId: string;
  customerNumber: string | null = null;

  constructor(element: HTMLElement, orderId: string) {
    this.element = element;
    this.orderId = orderId;

    const elementId = uuidv4();

    const inputElement = document.createElement('input');
    inputElement.setAttribute('class', 'form-control form-icon-trailing');
    inputElement.setAttribute('id', elementId);
    inputElement.setAttribute('placeholder', 'Zoek artikel');

    const labelElement = document.createElement('label');
    labelElement.innerHTML = 'Artikel';
    labelElement.setAttribute('class', 'form-label');
    labelElement.setAttribute('for', elementId);

    // Create <input type="hidden" name="..."> element.
    const hiddenInputElement = document.createElement('input');
    hiddenInputElement.setAttribute('name', this.element.dataset.productFinder || '');
    hiddenInputElement.setAttribute('type', 'hidden');

    // Create feedback element.
    const feedbackElement = document.createElement('div');
    feedbackElement.dataset.validateFor = this.element.dataset.productFinder || '';
    feedbackElement.setAttribute('class', 'invalid-feedback');

    this.element.setAttribute('class', ('form-outline autocomplete ' + this.element.getAttribute('class')).trim());
    this.element.appendChild(inputElement);
    this.element.appendChild(labelElement);
    this.element.appendChild(hiddenInputElement);
    this.element.appendChild(feedbackElement);

    new mdb.Input(this.element).init();

    new mdb.Autocomplete(this.element, {
      filter: (query: string) => {
        return this.asyncFilter(query);
      },
      displayValue: (product: Product) => product.code + ' - ' + product.term,
      itemContent: (product: Product) => {
        return `
              <div class="autocomplete-custom-item-content">
                <div class="autocomplete-custom-item-title">${product.term}</div>
                <div class="autocomplete-custom-item-subtitle"><strong>${product.code}</strong></div>
              </div>
            `;
      },
      noResults: 'Geen artikelen gevonden',
      threshold: 2,
      autoSelect: true,
    });

    inputElement.addEventListener('click', () => {
      inputElement.value = '';
      hiddenInputElement.value = '';
    });

    // eslint-disable-next-line
        this.element.addEventListener('itemSelect.mdb.autocomplete', (e: any) => {
      const product = e.value;
      product.orderId = this.orderId;

      hiddenInputElement.value = product.id;

      EventBus.getInstance().dispatch<Product>('product.selected', product);
    });

    EventBus.getInstance().register('customer.changed', (customer: Customer) => {
      this.customerNumber = customer.number;
    });
  }

  async asyncFilter(query: string) {
    if (query.length < 3) {
      return [];
    }

    let url = SIJPERDA_API_BASE + `v1/products?query=${encodeURI(query)}`;

    if (this.customerNumber !== null) {
      url = SIJPERDA_API_BASE + `v1/products/${this.customerNumber}?query=${encodeURI(query)}`;
    }

    const response = await fetch(url);

    return await response.json();
  }
}

export const productFinderPlugin = (parent: HTMLElement, orderId: string): void => {
  parent.querySelectorAll<HTMLElement>('[data-product-finder]').forEach((finder) => {
    new ProductFinder(finder, orderId);
  });
};
